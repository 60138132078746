import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import { ContactHero } from '../components/Hero'
import { ContactUsForm } from '../components/FormSections'
import { useContactUs } from '../GraphQL/Queries'

const ContactUs = () => {
    const data = useContactUs()
    const hero = {
        image: data.acf_hero.heroImage.sourceUrlSharp.childImageSharp.fluid,
        alt: data.acf_hero.heroImage.altText,
    }
    return (
        <Layout>
            <SEO title="Contact Us" />
            <ContactHero image={hero.image} alt={hero.alt}>
                <ContactUsForm />
            </ContactHero>
        </Layout>
    )
}

export default ContactUs
